<template>
  <div class="exam-page page-main">
    <a-spin :spinning="loading">
      <div
        class="container"
        :style="{
          'justify-content': examList?.length > 0 ? 'unset' : 'center',
        }"
      >
        <template v-if="examList && examList.length > 0">
          <div
            class="box box-shadow-1"
            v-for="item in examList"
            :key="item.exam_id"
          >
            <div class="name ellipsis">{{ item.exam_name }}</div>
            <div class="score ellipsis">
              总分：{{ item.exam_total_score }}分 通过分数：
              <span style="color: #bb2f2f">{{ item.exam_pass_score }}分</span>
            </div>
            <div
              class="school ellipsis"
              :title="`${item.school_name}-${item.class_name}`"
            >
              {{ item.school_name }}
              -
              {{ item.class_name }}
            </div>
            <div
              class="time ellipsis"
              :title="`${item.exam_start_time}-${item.exam_end_time}`"
            >
              开放时间：{{ item.exam_start_time }}
              -
              {{ item.exam_end_time }}
            </div>
            <div class="flex-between bar">
              <div class="exam_score" v-if="item.exam_record.length == 0">
                未开始
              </div>
              <div class="exam_score" v-else>
                最高分：{{ getMax(item.exam_record) }}
              </div>
              <div class="start-btn" @click="startExam(item)">开始考试</div>
            </div>
          </div>
        </template>
        <a-empty v-if="examList.length == 0" description="暂无考试"></a-empty>
      </div>
    </a-spin>
    <a-modal
      title="考试信息"
      width="720px"
      v-model:visible="showExam"
      :maskClosable="false"
      :footer="false"
      :centered="true"
      :bodyStyle="{
        height: 'calc(100vh - 180px)',
        overflowY: 'auto',
      }"
      @cancel="cancelModal"
    >
      <div class="confirm-page">
        <div v-if="exam" class="confirm-main">
          <div class="container" style="padding-top: 0">
            <div class="name">{{ exam.exam_name }}</div>
          </div>
          <div class="confirm-wrapper">
            <div class="container">
              <div class="cate_title title-icon">考试信息</div>
              <div class="flex info">
                <div class="item">
                  <div class="value">{{ getTime(exam.exam_duration) }}</div>
                  <div class="label">考试时间</div>
                </div>
                <div class="line">|</div>
                <div class="item">
                  <div class="value">{{ exam.exam_question_num }}</div>
                  <div class="label">题目数量（题）</div>
                </div>
                <div class="line">|</div>
                <div class="item">
                  <div class="value">{{ exam.exam_total_score }}</div>
                  <div class="label">试卷总分（分）</div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="cate_title title-icon">考试详情</div>
              <div class="flex detail">
                <div class="left">考试时间：</div>
                <div class="content">
                  {{ exam.exam_start_time }}至{{ exam.exam_end_time }}
                  <div>超过规定时间就不可以参加考试</div>
                </div>
              </div>
              <div class="flex detail">
                <div class="left">通过分数：</div>
                <div class="content" style="color: #bb2f2f">
                  {{ exam.exam_pass_score }}分
                </div>
              </div>
              <div class="flex detail">
                <div class="left">考试次数：</div>
                <div class="content">
                  本场考试您可参与
                  {{ exam.exam_sum == -1 ? "不限" : exam.exam_sum }}次
                </div>
              </div>
              <div class="flex detail">
                <div class="left">注意事项：</div>
                <div
                  class="content detail-content"
                  v-html="exam.exam_content"
                ></div>
              </div>
            </div>
          </div>
          <a-button
            type="primary"
            size="large"
            block
            class="confirm-btn"
            @click="goToExam()"
          >
            开始考试
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, createVNode } from "vue";
import { getExamList, insertExamRecordData } from "@/api/main.js";
import url from "@/utils/url.js";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { getUserId } from "@/utils/storeData.js";

export default defineComponent({
  name: "Exam",
  setup() {
    const pageData = reactive({
      examList: [],
      loading: false,
      user_id: computed(() => getUserId()),
      showExam: false,
      exam: null,
    });
    const getExamListFun = () => {
      pageData.loading = true;
      getExamList({ user_id: pageData.user_id })
        .then((res) => {
          if (res.code == 200) {
            pageData.examList = res.data.exam;
          } else {
            message.error(res.msg);
          }
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    getExamListFun();

    const startExam = (record) => {
      pageData.exam = record;
      pageData.showExam = true;
    };
    const getTime = (time) => {
      let hours = time >= 3600 ? Math.floor(time / 3600) : "";
      let minutes = time >= 60 ? Math.floor((time % 3600) / 60) : "";
      let seconds = (time % 3600) % 60;
      return `${hours.toString().padStart(2, 0)}时${minutes
        .toString()
        .padStart(2, 0)}分${seconds.toString().padStart(2, 0)}秒`;
    };
    const goToExam = () => {
      Modal.confirm({
        title: "考试提醒",
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          "div",
          {
            style: "color:red;",
          },
          "考试开始后即消耗考试次数，中途不可退出，是否开始考试？"
        ),

        onOk() {
          insertExamRecordData({
            exam_id: pageData.exam.exam_id,
            student_id: pageData.exam.student_id,
          })
            .then((res) => {
              if (res.code == 200) {
                url.navigateTo("/exam/exam", {
                  recordId: res.data.record_id,
                });
              } else {
                message.error(res.msg);
              }
            })
            .catch((res) => {
              console.log(res);
            });
        },

        onCancel() {
          console.log("Cancel");
        },
      });
    };
    const cancelModal = () => {
      pageData.showExam = false;
      pageData.exam = null;
    };

    const getMax = (item) => {
      item.sort(
        (a, b) => Number(b.record_exam_score) - Number(a.record_exam_score)
      );
      return item[0].record_exam_score;
    };

    return {
      ...toRefs(pageData),
      startExam,
      getTime,
      goToExam,
      cancelModal,
      getMax,
    };
  },
});
</script>

<style lang="less" scoped>
.exam-page {
  height: 100%;
}
.container {
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;

  .box {
    width: 384px;
    margin-right: 24px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 30px 20px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-size: 14px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    .ellipsis {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      color: #333333;
      font-size: 18px;
    }

    .score {
      margin-top: 18px;
      color: #333333;
    }

    .school {
      color: #666666;
      margin-top: 8px;
    }

    .time {
      color: #969696;
      margin-top: 8px;
      margin-bottom: 20px;
    }

    .line {
      display: inline-block;
      margin: 0 10px;
    }

    .start-btn {
      // width: 160px;
      padding: 0 12px;
      height: 30px;
      line-height: 30px;
      background-color: #1e83e5;
      border-radius: 24px;
      text-align: center;
      color: #ffffff;
      font-size: 13px;
      cursor: pointer;
    }

    .bar {
      padding-top: 20px;
      padding-bottom: 10px;
      border-top: 1px solid #e0e0e0;
    }

    .exam_score {
      color: #000000;
      font-size: 15px;
    }
  }
}
</style>
<style lang="less" scoped>
.confirm-page {
  height: 100%;
  .confirm-main {
    padding: 0 36px 30px;
    width: 672px;
    margin: 0 auto;
    overflow-y: auto;
    margin-bottom: 10px;
  }
}
.container {
  padding: 18px 0px;
  .cate_title {
    margin-bottom: 24px;
    font-size: 16px;
  }
  .name {
    color: #333333;
    font-size: 24px;
  }

  .info {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    .item {
      width: 200px;
      text-align: center;
    }

    .value {
      color: #333333;
    }

    .label {
      color: #969696;
      font-size: 14px;
      margin-top: 30px;
    }

    .line {
      color: #eeeeee;
    }
  }

  .detail {
    margin-bottom: 30px;
    font-size: 16px;
    align-items: unset;
    width: 600px;
    .left {
      color: #969696;
      width: 90px;
      flex: 0 0 auto;
    }

    .content {
      color: #333333;
      width: calc(100% - 90px);
      text-align: center;
    }
    .detail-content {
      text-align: unset;
    }
  }
}

.title-icon {
  position: relative;
  padding-left: 12px;
}
.title-icon::after {
  content: "";
  display: block;
  width: 4px;
  height: 100%;
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1890ff;
}
</style>
<style lang="less">
.content {
  img {
    max-width: 100% !important;
  }
}
</style>
